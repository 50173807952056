import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import Box from "@components/styled/Box";
import Container from "@components/styled/Container";
import Layout from "@components/layout";
import SEO from "@components/seo";
import { rem } from "@src/theme";
// A great library for fuzzy filtering/sorting items
import Columns from "@components/styled/Columns";
import Column from "@components/styled/Column";
import TextInput from "@components/TextInput";
import { fuzzyTextFilterCb } from "@helpers/data";
import { ClipLoader } from "react-spinners";
import { authCheckRedirect } from "@helpers/auth";
import AddButton from "@components/AddButton";
import { base } from "@helpers/airtable";
import StatCard from "@components/StatCard";

const StatsTemplate = (props) => {
  const [recordsData, setRecordsData] = useState([]);
  const [filterQuery, setFilterQuery] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    authCheckRedirect();
    const fetchData = async () => {
      setLoading(true);

      const result = await base(`${props.name} Stat`)
        .select({
          view: "Grid view",
        })
        .all();

      const cards = result.map((record) => {
        delete record.fields["Annual Summary"];
        delete record.fields["National View"];
        delete record.fields["Annual Link"];
        delete record.fields["Sum"];

        const card = {
          id: record.id,
          name: record.fields["Month-Year"],
          fields: record.fields,
        };

        delete record.fields["Month-Year"];

        return card;
      });

      setRecordsData(cards);

      setLoading(false);
    };

    fetchData();
  }, []);

  const handleTextFilterChange = (e) => {
    setFilterQuery(e.target.value);
  };

  const renderRecords = recordsData.length ? (
    recordsData
      .filter((record) =>
        fuzzyTextFilterCb(filterQuery, record, recordsData, ["name"])
      )
      .map((record) => (
        <Column key={record.name} width={[1, 1, 1 / 2]} mb={2}>
          <StatCard
            stat={record.fields}
            name={record.name}
            period={record.name}
            type={props.name}
          />
        </Column>
      ))
  ) : !loading && !recordsData.length ? (
    <Column width={1}>
      <Box textAlign="center">
        There aren't any stats, add one <Link to="/stat">here</Link>
      </Box>
    </Column>
  ) : (
    <Column width={1}>
      <Box display="flex" justifyContent="center">
        <ClipLoader sizeUnit="px" size={150} />
      </Box>
    </Column>
  );
  return (
    <Layout uri={props.uri}>
      <SEO title={`${props.name} stats`} />

      <Container pt={7}>
        <Box
          as="h1"
          fontSize={rem(28)}
          mb={4}
          p={4}
          pr={6}
          bg="blue"
          color="white"
          display="inline-block"
        >
          {`${props.name} stats`}
        </Box>

        <Box as="section" my={6}>
          <Box width={rem(300)}>
            <label htmlFor="name-filter"></label>
            <TextInput
              name="name-filter"
              value={filterQuery}
              onChange={handleTextFilterChange}
              onBlur={() => {}}
              placeholder="Type in a month/year"
            />
          </Box>
        </Box>
        <AddButton to="/stat" />
        <Columns>{renderRecords}</Columns>
      </Container>
    </Layout>
  );
};

export default StatsTemplate;
