import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Box from "@components/styled/Box"
import { rem } from "@src/theme"

const AddButton = ({ to, label = "Add" }) => {
  return (
    <Outer to={to}>
      <Box display="flex" alignItems="center">
        {label}
      </Box>
    </Outer>
  )
}

export default AddButton

export const Outer = styled(Link)`
  display: inline-block;
  background-color: ${p => p.theme.colors.green};
  color: ${p => p.theme.colors.white};
  font-family: "NeueDisplay";
  border: 1px solid ${p => p.theme.colors.green};
  border-radius: 100px;
  padding: ${rem(6)} ${rem(25)} ${rem(6)} ${rem(25)};
  text-transform: uppercase;
  font-size: ${rem(12)};
  text-decoration: none;
  margin-bottom: ${p => p.theme.space[5]};
`
