import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import Box from "@components/styled/Box";
import { rem } from "@src/theme";
import Columns from "@components/styled/Columns";
import Column from "@components/styled/Column";
import Down from "@components/assets/svg/caret-down.svg";
import Up from "@components/assets/svg/caret-up.svg";

class StatCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false
    };
  }

  toggleExpand = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ expanded: !this.state.expanded });
  };
  render() {
    const { stat, name } = this.props;
    const mapKeyValues = () => {
      const values = [];
      for (let key in stat) {
        if (key !== "name") {
          values.push({ key, value: stat[key] });
        }
      }

      return values;
    };
    const values = mapKeyValues();

    const renderValues = values.slice(0, 4).map(o => (
      <Column width={[1, 0.5]} mb={3} key={o.key}>
        <DescriptionList>
          <dt>{o.key}</dt>
          <dd>{o.value}</dd>
        </DescriptionList>
      </Column>
    ));

    const renderRestValues = values.slice(4).length
      ? values.slice(4).map(o => (
          <Column width={[1, 0.5]} mb={3} key={o.key}>
            <DescriptionList>
              <dt>{o.key}</dt>
              <dd>{o.value}</dd>
            </DescriptionList>
          </Column>
        ))
      : null;

    return (
      <Outer to={`/stat/?period=${this.props.period}&type=${this.props.type}`}>
        <Box bg="offWhite" width="100%" borderRadius="2px">
          <Box p={4}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mb={4}
            >
              <Box width="95%">
                <Box as="h4" pr={5}>
                  {name}
                </Box>
              </Box>
            </Box>

            <Columns>{renderValues}</Columns>
            {renderRestValues ? (
              <Expand>
                <Box
                  onClick={this.toggleExpand}
                  width={1}
                  display="flex"
                  justifyContent="center"
                  mb={this.state.expanded ? 5 : 0}
                >
                  {this.state.expanded ? <Up /> : <Down />}
                </Box>

                {this.state.expanded && <Columns>{renderRestValues}</Columns>}
              </Expand>
            ) : null}
          </Box>
        </Box>
      </Outer>
    );
  }
}

const Outer = styled(Link)`
  text-decoration: none;
  display: block;
  height: 100%;
  width: 100%;
  color: ${p => p.theme.colors.black};
`;

const DescriptionList = styled.dl`
  dt {
    font-size: ${rem(10)};
    text-transform: uppercase;
  }
  dd {
    font-weight: 600;
    font-size: ${rem(20)};
  }
`;

const Expand = styled.div`
  padding-top: ${p => p.theme.space[3]};
  border-top: 1px solid #e8e8e8;

  svg {
    color: ${p => p.theme.colors.navyBlack};
  }
`;

export default StatCard;
